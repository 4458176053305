import { IconMegaphone } from "@web-monorepo/dds-icons";
import css from "./styles/BadgedMegaphone.module.css";
import {
  setDismissedAlertQuery,
  useGetDismissedHelpAlert,
  useSetDismissedHelpAlert,
} from "src/hooks/api/tutor/useTutorDismissedUpdates";
import { metrics } from "#/src/utils/metrics";
import { DDSCoachmark } from "@web-monorepo/dds";
// const CircleSvg = ({ className }: { className: string }) => (
//   <svg className={className} xmlns="http://www.w3.org/2000/svg">
//     <circle cx="9" cy="9.5" r="4" fill="#D92218" stroke="white" />
//   </svg>
// );

const TUTOR_HELP_URL =
  "https://tutor-help.classdojo.com/hc/en-us/search?content_tags=01JF871R241GH0QBZDFD48N18F&utf8=%E2%9C%93";

const BadgedMegaphone = () => {
  const { data: dismissedHelpAlertData } = useGetDismissedHelpAlert();
  const setDismissedHelpAlert = useSetDismissedHelpAlert();

  return (
    <div className={css.container}>
      <a href={TUTOR_HELP_URL} target="_blank" className={css.megaphone}>
        <IconMegaphone size="m" />
        {/* <CircleSvg className={css.circle} /> */}
      </a>
      <DDSCoachmark
        isOpen={Boolean(dismissedHelpAlertData && !dismissedHelpAlertData.dismissedAt)}
        title="New! Keep track of updates"
        description="Stay informed about the latest and greatest from Dojo Tutor here."
        primaryActionText="Done"
        trianglePosition="topRight"
        primaryAction={() => {
          const date = new Date().toISOString();
          setDismissedHelpAlert.mutate(
            { dismissedAt: date },
            {
              onSuccess: () => {
                setDismissedAlertQuery({ dismissedAt: date });
              },
              onError: (error: any) => {
                metrics.increment("marketplace.tutor.setDismissedHelpAlertError", {
                  message: JSON.stringify(error),
                });
              },
            },
          );
        }}
      />
    </div>
  );
};

export default BadgedMegaphone;
