import type { FC, MouseEventHandler } from "react";
import { IconMinus } from "@web-monorepo/dds-icons";
import styles from "./XButton.module.css";

type Props = {
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const XButton: FC<Props> = ({ className, onClick }) => (
  <button
    aria-label="cancel"
    className={[styles.xButton, className].join(" ")}
    onClick={onClick}
  >
    <IconMinus size="s" color={"dt_content_primary"} />
  </button>
);

export default XButton;
