import { theme } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { ReactNode, useCallback, useContext } from "react";
import { RadioGroupContext } from "./index";

type Props<TValue> = {
  children: ReactNode;
  disabled?: boolean;
  value: TValue;
  sxOption?: ThemeUIStyleObject;
  name?: string;
  dotSize?: number;
  sxDot?: ThemeUIStyleObject;
};
// eslint-disable-next-line comma-spacing
const RadioGroupOption = <TValue,>({
  children,
  disabled = false,
  value,
  sxOption,
  name,
  dotSize,
  sxDot: _sxDot,
}: Props<TValue>) => {
  const context = useContext(RadioGroupContext);
  const onClick = useCallback(() => {
    context.onChange(value);
  }, [context, value]);
  const checked = context.value === value;

  const sxDot: ThemeUIStyleObject = {
    display: "flex",
    borderWidth: 2,
    borderColor: "dt_content_tertiary",
    borderStyle: "solid",
    width: 24,
    minWidth: 24,
    height: 24,
    borderRadius: "dt_radius_round",
    alignItems: "center",
    justifyContent: "center",

    '[data-checked="true"] &': {
      borderColor: "dt_border_active",

      "&::after": {
        content: '""',
        width: dotSize ?? 16,
        height: dotSize ?? 16,
        backgroundColor: "dt_content_accent",
        borderRadius: "dt_radius_round",
        display: "block",
      },
    },

    '[data-disabled="true"] &': {
      borderColor: "dt_border_disabled",
    },
    ..._sxDot,
  };

  return (
    <label
      sx={{ ...sxOptionLabel, ...sxOption }}
      data-disabled={disabled}
      data-dot-position={context.dotPosition}
      data-checked={value === context.value}
    >
      <input
        type="radio"
        name={name || `radio-group-option-${context.id}`}
        onChange={disabled || checked ? undefined : onClick}
        checked={value === context.value}
        disabled={disabled}
        tabIndex={0}
      />
      <div sx={{ ...sxDot }} />
      <div sx={sxText}>{children}</div>
    </label>
  );
};

export default RadioGroupOption;

const sxOptionLabel: ThemeUIStyleObject = {
  cursor: "pointer",
  borderRadius: "dt_radius_s",
  display: "flex",
  flexDirection: "row",
  gap: "dt_m",
  padding: "dt_m",
  fontWeight: 600,
  fontSize: "18px",

  "&>input": {
    pointerEvents: "none",
    opacity: 0,
    position: "absolute",
    left: "-100vw",
  },

  "&:has(:focus-visible)": {
    boxShadow: RAW_cssValue(`0 0 0 2px ${theme.colors.dt_content_accent} inset`),
  },

  '&[data-disabled="true"]': {
    cursor: "default",
    backgroundColor: "dt_background_secondary",
    color: "dt_content_disabled",
  },

  '&[data-checked="true"]': {
    cursor: "default",
    backgroundColor: "dt_background_accent",
  },

  '&[data-dot-position="right"]': {
    flexDirection: "row-reverse",
  },
};

const sxText: ThemeUIStyleObject = {
  flexGrow: 1,
};
