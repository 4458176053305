import { paths } from "@classdojo/ts-api-types/api";
import { useQuery } from "@tanstack/react-query";
import { monolithApiClient } from "src/configs/axios.config";
import useMutation from "src/hooks/useMutation";
import queryClient from "src/queryClient";

type GetEndpoint = paths["/api/marketplace/userConfigValueStore/dismissedHelpAlert"]["get"];
type GetResponse = GetEndpoint["responses"]["200"]["content"]["application/json"];

type PutEndpoint = paths["/api/marketplace/userConfigValueStore/dismissedHelpAlert"]["put"];
type Body = PutEndpoint["requestBody"]["content"]["application/json"];
type PutResponse = PutEndpoint["responses"]["200"];

export const useGetDismissedHelpAlert = () => {
  const { data } = useQuery(["dismissedHelpAlert"], () =>
    monolithApiClient.get<GetResponse>(`/userConfigValueStore/dismissedHelpAlert`),
  );

  return {
    data: data?.data,
  };
};

export const setDismissedHelpAlert = (body: Body) => {
  return monolithApiClient.put<PutResponse>(`/userConfigValueStore/dismissedHelpAlert`, body);
};

export function useSetDismissedHelpAlert() {
  return useMutation(setDismissedHelpAlert);
}

export const setDismissedAlertQuery = (data: GetResponse) => {
  queryClient.setQueryData(["dismissedHelpAlert"], (oldData: GetResponse | undefined) => {
    if (!oldData) return oldData;

    return {
      ...oldData,
      data,
    };
  });
};
