import type UppyType from "@uppy/core";
import { getUploadURL } from "../shared";
import { type UppyFile } from "@uppy/core";

interface GetUppy {
  getBucket?: (file: File | UppyFile) => string;
}
export const getUppy = async ({ getBucket }: GetUppy): Promise<UppyType> => {
  const [{ default: S3 }, { default: Uppy }] = await Promise.all([
    import("@uppy/aws-s3"),
    import("@uppy/core"),
  ]);

  return new Uppy({
    id: "@file-upload/AwsS3",
  }).use(S3, {
    async getUploadParameters(file) {
      const config = await getUploadURL(file, getBucket);
      return {
        method: "PUT",
        url: config.url,
        fields: {
          key: config.key,
          urlSmall: config.urlSmall,
          cdnUrl: config.cdnUrl,
        },
        headers: config.headers,
      };
    },
  });
};
