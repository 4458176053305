import { showNotification } from "@mantine/notifications";
import { BookingType, useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import { useCallback } from "react";
import { useNavigate } from "#/src/routes";

export const isBrowser = () => typeof window !== "undefined";

export const showErrorNotification = (title?: string, message?: string) => {
  showNotification({
    title: title || "Something went wrong!",
    message: message || "An error has occured. Please try again in a few minutes or you may send us a message.",
    color: "red",
  });
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const urlToFile = (url: string, filename: string) => {
  const mimeType = (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

type SubscribeParentPayload = Pick<
  BookingType,
  "subject" | "grade" | "subscriptionClassId" | "teacher_uid" | "first_name_child"
>;
export const useSubscribe = () => {
  const [, dispatch] = useGlobalStore();
  const push = useNavigate();

  const subscribeParent = useCallback(
    (data: SubscribeParentPayload) => {
      const payload = {
        ...data,
        product: "",
        subscription: {},
        type: "subscription",
      };

      dispatch({ type: actionTypes.UPDATE_BOOKING, payload });
      push("/subscription/booking/select-class-frequency");
    },
    [dispatch, push],
  );

  return { subscribeParent };
};

export const validatePasswordAndReturnErrorMessage = (password: string) => {
  if (!password) {
    return "Password is required.";
  }
  if (password.length < 8) {
    return "Password should have more than 8 characters.";
  }
  if (password.length > 32) {
    return "Password should not exceed 32 characters.";
  }
  return null;
};
