import { useModalStore } from "#/src/components/Modals";
import ModalWrapper from "#/src/components/Modals/ModalWrapper";
import { DDSBodyText, DDSLabelText } from "@web-monorepo/dds";
import { Button } from "UILibrary";

const FeedbackTermsModal = () => {
  const hideModal = useModalStore((state) => state.hideModal);
  return (
    <ModalWrapper variant="centered" hideCloseButton>
      <div sx={{ display: "flex", flexDirection: "column", gap: "dt_l", padding: "dt_xs" }}>
        <DDSLabelText level={2}>Allow Dojo Tutor to share your feedback</DDSLabelText>

        <DDSBodyText level={3}>
          I expressly release, on behalf of myself and my heirs, executors, administrators, successors, and assigns,
          ClassDojo, and its affiliates, subsidiaries, partners, agents, successors, licensees, and assigns, and to
          other such entities or persons as ClassDojo may designate from time to time, from any and all claims, demands,
          liabilities, or causes of action, whether now known or unknown, based upon or relating to the use of my survey
          responses, including for defamation, copyright infringement, violation of moral rights, and invasion of the
          rights of privacy, publicity, or personality or any similar matter.
        </DDSBodyText>

        <DDSBodyText level={3}>
          I hereby waive any and all rights to seek or obtain any injunctive or other equitable relief for any actions
          arising out of this release or with respect to any use of my survey responses.
        </DDSBodyText>

        <Button onClick={hideModal}>Close</Button>
      </div>
    </ModalWrapper>
  );
};

export default FeedbackTermsModal;
