import { AuthErrorResponse } from "#/src/hooks/api/auth/useLoginMutation";
import { isApiResponseError } from "@web-monorepo/infra/responseHandlers";
import { AxiosError } from "axios";

export const getErrorMetadata = (error: AxiosError<AuthErrorResponse["content"]["application/json"]>) => {
  const isApiError = isApiResponseError(error);
  const isAuthError = error?.response?.status === 401;
  if (!isApiError || !isAuthError) {
    return null;
  }

  const loginErrorCode = error.response?.data?.error?.code;

  const mustUseOtc = loginErrorCode?.startsWith("ERR_MUST_USE_OTC");
  const wrongUsername = loginErrorCode?.startsWith("ERR_INVALID_EMAIL_ADDRESS");
  const wrongPassword = loginErrorCode?.startsWith("ERR_INVALID_PASSWORD");

  const errors = {
    wrongPassword,
    wrongUsername,
    mustUseOtc,
  };

  return Object.values(errors).some(Boolean) ? errors : null;
};
