// Generouted, changes to this file will be overriden
/* eslint-disable */

import { hooks, utils } from "@generouted/react-router/client";
import { components } from "@web-monorepo/routing";

export type Path =
  | `/`
  | `/additional-single-class/step-five`
  | `/additional-single-class/step-four`
  | `/additional-single-class/step-one`
  | `/additional-single-class/step-six`
  | `/additional-single-class/step-three`
  | `/additional-single-class/step-two`
  | `/apply/status`
  | `/apply/teacher`
  | `/apply/teacher/application`
  | `/book-more-classes`
  | `/book-more-classes/with-tutor/:tutorId`
  | `/booking`
  | `/booking/:enrollmentId`
  | `/booking/:enrollmentId/success`
  | `/direct-booking-subscription/child-information`
  | `/direct-booking-subscription/parent-signup`
  | `/direct-booking-subscription/subject-selection`
  | `/direct-booking-subscription/tutor-introduction/:tutorId`
  | `/faq`
  | `/for-teachers`
  | `/forgot-password`
  | `/forgot-password/reset/:token`
  | `/funnel/:funnelId`
  | `/how-dojo-tutor-works`
  | `/login`
  | `/login/code`
  | `/login/code/:email`
  | `/parents/classes`
  | `/parents/pastClasses`
  | `/parents/pastClasses/:sessionId`
  | `/parents/reschedule/subscription/class/:classId`
  | `/parents/reschedule/subscription/session/:sessionId`
  | `/parents/reschedule/trial/session/:sessionId`
  | `/privacy`
  | `/single-class`
  | `/single-class/:stepName`
  | `/subscription/booking/checkout`
  | `/subscription/booking/select-class-frequency`
  | `/subscription/booking/select-times`
  | `/subscription/manage`
  | `/subscription/manage/:subscriptionId/cancel/complete`
  | `/subscription/manage/:subscriptionId/cancel/confirmation`
  | `/subscription/manage/:subscriptionId/cancel/reason`
  | `/subscription/manage/:subscriptionId/pause`
  | `/subscription/manage/:subscriptionId/pause/complete/:pausedUntil`
  | `/subscription/reschedule/:subscriptionId`
  | `/teachers/calendar`
  | `/teachers/calendar/:sessionId`
  | `/teachers/profile`
  | `/termsofservice`
  | `/tutor/apply`
  | `/winter-math-boost`
  | `/winter-reading-boost`;

export type Params = {
  "/book-more-classes/with-tutor/:tutorId": { tutorId: string };
  "/booking/:enrollmentId": { enrollmentId: string };
  "/booking/:enrollmentId/success": { enrollmentId: string };
  "/direct-booking-subscription/tutor-introduction/:tutorId": { tutorId: string };
  "/forgot-password/reset/:token": { token: string };
  "/funnel/:funnelId": { funnelId: string };
  "/login/code/:email": { email: string };
  "/parents/pastClasses/:sessionId": { sessionId: string };
  "/parents/reschedule/subscription/class/:classId": { classId: string };
  "/parents/reschedule/subscription/session/:sessionId": { sessionId: string };
  "/parents/reschedule/trial/session/:sessionId": { sessionId: string };
  "/single-class/:stepName": { stepName: string };
  "/subscription/manage/:subscriptionId/cancel/complete": { subscriptionId: string };
  "/subscription/manage/:subscriptionId/cancel/confirmation": { subscriptionId: string };
  "/subscription/manage/:subscriptionId/cancel/reason": { subscriptionId: string };
  "/subscription/manage/:subscriptionId/pause": { subscriptionId: string };
  "/subscription/manage/:subscriptionId/pause/complete/:pausedUntil": { subscriptionId: string; pausedUntil: string };
  "/subscription/reschedule/:subscriptionId": { subscriptionId: string };
  "/teachers/calendar/:sessionId": { sessionId: string };
};

export type ModalPath = never;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>();
export const { redirect } = utils<Path, Params>();
