import { useLayoutEffect } from "react";
import useHelperWidgetAPI from "./useHelperWidgetAPI";

const useHideHelperWidget = (showWidgetOnCleanup?: boolean) => {
  const helperWidgetAPI = useHelperWidgetAPI();
  useLayoutEffect(() => {
    helperWidgetAPI.hideWidget();
    return () => {
      if (showWidgetOnCleanup) return;
      helperWidgetAPI.showWidget();
    };
  }, [showWidgetOnCleanup, helperWidgetAPI]);
};

export default useHideHelperWidget;
