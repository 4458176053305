import { createStyles } from "@mantine/core";
import { NAVBAR_BREAKPOINT } from "old/src/UILibrary/constants";
import { HEADER_HEIGHT } from "src/constants";
import marketplaceTheme from "src/utils/marketplaceTheme";

export default createStyles((theme) => ({
  headerActions: {
    display: "flex",
    alignItems: "center",
    zIndex: 10,
    columnGap: "var(--sp-9)",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      display: "none",
    },
  },
  links: {
    flex: 1,
    marginLeft: theme.spacing.xl,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: theme.spacing.md,
  },
  mobileHeaderActions: {
    display: "none",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      display: "flex",
    },
    gap: "var(--sp-9)",
    pointerEvents: "all",
  },
  privateHeaderTabs: {
    display: "flex",
    alignItems: "center",

    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      display: "none",
    },
  },
  privateHeaderTabLink: {
    color: theme.colors.taro[7],
    fontSize: "18px",
    fontWeight: 600,
    textDecoration: "none",
    lineHeight: `${HEADER_HEIGHT}px`,

    ":hover": {
      color: `${marketplaceTheme.colors.dt_content_accent} !important`,
    },
  },

  privateHeaderTabLinkActive: {
    color: `${marketplaceTheme.colors.dt_content_accent} !important`,
    position: "relative",

    ":after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: "2px",
      width: "100%",
      background: marketplaceTheme.colors.dt_content_accent,
    },
  },
}));
