// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPinLocationFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 10.5324C3.5 5.8195 7.30515 2 12.0004 2C16.6956 2 20.5008 5.8195 20.5008 10.5324C20.5008 13.5897 18.9287 16.1614 17.2199 18.0599C15.5058 19.9636 13.5905 21.2611 12.7575 21.7814C12.5307 21.9246 12.2685 22.0008 12.0004 22.0008C11.7322 22.0008 11.4701 21.9246 11.2433 21.7814C10.411 21.2604 8.49492 19.9636 6.78087 18.0599C5.07209 16.1614 3.5 13.5889 3.5 10.5324Z"
      />
    </svg>
  );
};
