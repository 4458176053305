import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/checkout/payment-intent/{classId}"]["post"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export default (
  id: string,
  promoCode?: string,
  applyDirectSubPrices?: boolean,
  typeOfTrial?: "directSubscription" | "single",
) =>
  monolithApiClient.post<Response>(`/checkout/payment-intent/${id}`, {
    promoCode,
    applyDirectSubPrices: applyDirectSubPrices ?? false,
    typeOfTrial,
  });
