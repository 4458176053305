import { jsx, Fragment } from 'react/jsx-runtime';

const AT = ({ children }) => /* @__PURE__ */ jsx(Fragment, { children });

function autoTranslate(text, substitutions) {
  return text.replaceAll(
    /__([a-zA-Z0-9]+)__/gm,
    (_match, p1) => substitutions?.[p1] ?? ""
  );
}

function SkipTranslate({ children }) {
  return /* @__PURE__ */ jsx("div", { className: "notranslate", translate: "no", children });
}

export { AT as A, SkipTranslate as S, autoTranslate as a };
