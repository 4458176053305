// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconFavoriteFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3949 2.90736C11.123 1.69755 12.877 1.69755 13.6051 2.90736L15.5988 6.22022C15.8603 6.65484 16.287 6.96481 16.7811 7.07927L20.5479 7.95167C21.9235 8.27025 22.4655 9.93837 21.5399 11.0047L19.0053 13.9245C18.6728 14.3076 18.5098 14.8091 18.5537 15.3145L18.888 19.1665C19.0101 20.5732 17.5911 21.6042 16.2909 21.0534L12.7308 19.5451C12.2637 19.3472 11.7363 19.3472 11.2692 19.5451L7.70905 21.0534C6.40892 21.6042 4.98994 20.5732 5.11203 19.1665L5.44633 15.3145C5.49019 14.8091 5.32723 14.3076 4.9947 13.9245L2.46007 11.0047C1.53446 9.93837 2.07647 8.27025 3.45205 7.95167L7.21886 7.07927C7.71304 6.96481 8.13968 6.65484 8.40124 6.22022L10.3949 2.90736Z" />
    </svg>
  );
};
