import { Button, HeadlineText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { FormEventHandler, useCallback, useState } from "react";
import { useParentMutation } from "old/src/hooks/useParent";
import { logTutorEvent } from "src/utils/log";
import { DDSBodyText, DDSLabelText } from "@web-monorepo/dds";
import { BaseCheckbox as Checkbox } from "UILibrary";
import { UnstyledButton } from "@classdojo/web";
import Modal, { useModalStore } from "#/src/components/Modals";

type Props = {
  childName: string;
  liked?: boolean;
  onSubmit: (liked: boolean) => void;
  token: string;
  tutorName: string;
  classId: string;
  teacherId: string;
};

const SurveyForm = ({ childName, liked, classId, teacherId, onSubmit, token, tutorName }: Props) => {
  const [likedOverall, setLikedOverall] = useState(liked);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [publicConsent, setPublicConsent] = useState(false);
  const { reviewClass } = useParentMutation();
  const showFeedbackTermsModal = useModalStore((state) => state.showFeedbackTermsModal);

  const onFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      setDisabled(true);
      if (likedOverall == null) return;
      reviewClass.mutateAsync(
        { feedbackComment, token, likedOverall, publicConsent },
        { onSuccess: () => onSubmit(likedOverall) },
      );
    },
    [feedbackComment, onSubmit, likedOverall, reviewClass, token, publicConsent],
  );

  const onLikedOverall = useCallback(
    (value: boolean) => {
      setLikedOverall(value);
      logTutorEvent({
        eventName: `tutor.review.${value ? "thumbs_up.pressed" : "thumbs_down.pressed"}`,
        eventValue: classId,
        metadata: {
          teacherId,
        },
      });
    },
    [setLikedOverall, classId, teacherId],
  );

  const onTermsClick = useCallback(
    (e: React.MouseEvent) => {
      showFeedbackTermsModal();
      e.preventDefault();
    },
    [showFeedbackTermsModal],
  );

  const hasEnteredRating = likedOverall != null;

  return (
    <form sx={sxWrapper} onSubmit={onFormSubmit}>
      <Modal />

      <HeadlineText as="h2" sx={{ textAlign: "center" }}>
        Rate {childName}'s class with {tutorName}
      </HeadlineText>

      <div sx={{ display: "flex", flexDirection: "column", gap: "dt_s" }}>
        <DDSLabelText sx={{ alignSelf: "center" }}>Your feedback helps us get better!</DDSLabelText>

        <div sx={sxRatingWrapper}>
          <button
            sx={sxRatingButton}
            type="button"
            onClick={() => onLikedOverall(true)}
            data-selected={likedOverall === true}
            data-unselected={likedOverall === false}
          >
            <img sx={sxThumbsUp} src="parents/review/thumbs-up.svg" alt="Thumbs up" />
          </button>
          <button
            sx={sxRatingButton}
            type="button"
            onClick={() => onLikedOverall(false)}
            data-selected={likedOverall === false}
            data-unselected={likedOverall === true}
          >
            <img sx={sxThumbsDown} src="parents/review/thumbs-up.svg" alt="Thumbs down" />
          </button>
        </div>
      </div>
      {hasEnteredRating && (
        <div sx={{ display: "flex", flexDirection: "column", gap: "dt_xs" }}>
          <DDSLabelText>
            {likedOverall === true && <>What made this class special?</>}
            {likedOverall === false && <>How can we improve?</>}
          </DDSLabelText>
          <textarea
            sx={sxTextarea}
            placeholder="Add your feedback"
            onChange={(e) => setFeedbackComment(e.target.value)}
          />
        </div>
      )}
      {likedOverall === true && (
        <div sx={{ display: "flex", flexDirection: "column", gap: "dt_s" }}>
          <DDSLabelText level={2}>Allow Dojo Tutor to share your feedback</DDSLabelText>

          <div sx={{ display: "flex", gap: "dt_s" }}>
            <Checkbox
              id="review-public-consent"
              checked={publicConsent}
              onChange={(e) => setPublicConsent(e.target.checked)}
            />

            <label htmlFor="review-public-consent">
              <DDSBodyText level={3} sx={{ display: "inline-block", fontSize: "13.5px" }}>
                I allow ClassDojo to share my comment and name on their website and with my school community, in
                accordance with their{" "}
                <UnstyledButton
                  onClick={onTermsClick}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  data-name="survey_form:view_terms"
                >
                  terms
                </UnstyledButton>
                .
              </DDSBodyText>
            </label>
          </div>
        </div>
      )}
      <Button type="submit" disabled={!hasEnteredRating || disabled} data-name="survey_form:submit_review">
        Submit review
      </Button>
    </form>
  );
};

export default SurveyForm;

const sxWrapper: ThemeUIStyleObject = {
  maxWidth: 390,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "dt_l",
  paddingY: "dt_l",
  paddingX: "dt_m",
};

const sxRatingWrapper: ThemeUIStyleObject = {
  display: "flex",
  gap: "dt_l",
  justifyContent: "center",
};

const sxRatingButton: ThemeUIStyleObject = {
  borderRadius: "dt_radius_s",
  border: "dt_card",
  width: 68,
  height: 68,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&[data-selected=true]": {
    borderColor: "dt_border_active",
    color: "dt_content_accent",
  },

  "&[data-unselected=true]": {
    opacity: 0.6,
  },
};

const sxTextarea: ThemeUIStyleObject = {
  borderRadius: "dt_radius_s",
  border: "dt_card",
  height: 180,
  width: "100%",
  resize: "none",
  padding: "dt_l",

  "&::placeholder": {
    color: "dt_content_tertiary",
  },
};

const sxThumbsUp: ThemeUIStyleObject = { width: 32, display: "block" };

const sxThumbsDown: ThemeUIStyleObject = { ...sxThumbsUp, transform: "rotate(180deg)" };
