import React from "react";
import styles from "./coachmark.module.css";
import { DDSButton } from "../DDSButton/DDSButton";

export const TrianglePositions = ["topRight", "topLeft", "bottomRight", "bottomLeft", "left", "right"] as const;

interface DDSCoachmarkProps {
  isOpen: boolean;
  title?: string;
  description: string;
  primaryActionText: string;
  primaryAction: () => void;
  trianglePosition?: (typeof TrianglePositions)[number];
}

const DDSCoachmark: React.FC<DDSCoachmarkProps> = ({
  isOpen,
  title,
  description,
  primaryActionText,
  primaryAction,
  trianglePosition = "topRight",
}) => {
  if (!isOpen) return null;

  return (
    <div className={`${styles.wrapper} ${styles[trianglePosition]}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="19"
        viewBox="0 0 23 19"
        fill="none"
        className={styles.triangle}
      >
        <path
          d="M13.9512 1.47364C12.756 -0.220137 10.244 -0.220138 9.04883 1.47364L0.548074 13.5203C-0.854254 15.5076 0.566997 18.25 2.99924 18.25L20.0008 18.25C22.433 18.25 23.8543 15.5076 22.4519 13.5203L13.9512 1.47364Z"
          fill="white"
        />
      </svg>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.buttonContainer}>
          <DDSButton
            onClick={primaryAction}
            variant="primary"
            size="small"
            data-name="TDDS-Coachmark-Primary-Action"
            width="fit"
          >
            {primaryActionText}
          </DDSButton>
        </div>
      </div>
    </div>
  );
};

export { DDSCoachmark };
