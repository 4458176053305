import { IconPlus } from "@web-monorepo/dds-icons";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { useStore } from "../state";
import styles from "./Nav.module.css";

/* this is fine because the parent DropZone renders a form */
/* eslint-disable jsx-a11y/label-has-associated-control */

export default function Nav({
  fileInputName,
  maxAllowedFiles,
  onCancel,
}: {
  maxAllowedFiles: number;
  fileInputName: string;
  onCancel?(): void;
}) {
  const store = useStore();
  const snap = useSnapshot(store);

  return (
    <nav className={classnames(styles.nav, { [styles.noCancel]: !onCancel })}>
      {onCancel && (
        <div className={styles.cancel}>
          <button className={styles.cancelButton} onClick={onCancel}>
            <span className={styles.cancelText}>Cancel</span>
          </button>
        </div>
      )}

      {maxAllowedFiles > 1 && (
        <>
          <span className={styles.fileCount}>
            {`${snap.files.length} / ${maxAllowedFiles}`}
          </span>

          <button
            data-name="file_upload.dropzone.plus_button"
            className={styles.addMore}
            disabled={snap.files.length >= maxAllowedFiles}
            onClick={(event) => {
              event.preventDefault();
              document.getElementById(fileInputName)?.click();
            }}
            aria-label="Add more"
          >
            <IconPlus size="s" />
          </button>
        </>
      )}
    </nav>
  );
}
