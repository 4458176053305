import { useOnFirstRender } from "@web-monorepo/hooks";
import { BodyText, Button } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import startCase from "lodash/startCase";
import { Fragment, useMemo } from "react";
import { SubscriptionConflict } from "src/api/teacher/getSubscriptionConflicts";
import ModalWrapper from "src/components/Modals/ModalWrapper";
import { logTutorEvent } from "src/utils/log";

type ConflictConfirmationModalProps = {
  conflicts: SubscriptionConflict;
  onClose: () => void;
  onSave: () => void;
};

const ConflictConfirmationModal = ({ conflicts, onClose, onSave }: ConflictConfirmationModalProps) => {
  useOnFirstRender(() => {
    logTutorEvent("tutor.marketplace.edit_availability.affected_subscriptions_warning_opens");
  });

  const rows = useMemo(() => {
    if (conflicts == null) return [];

    const rowLookup: Record<string, { day: string; name: string; times: string[] }[]> = {};

    Object.entries(conflicts).forEach(([day, conflicts]) => {
      conflicts.forEach((conflict) => {
        rowLookup[conflict.subscriptionId] ??= [];

        rowLookup[conflict.subscriptionId].push({
          day,
          name: conflict.studentName,
          times: conflict.conflicts,
        });
      });
    });

    return Object.values(rowLookup);
  }, [conflicts]);

  return (
    <ModalWrapper
      variant="centered"
      title="Are you sure?"
      onClose={() => {
        logTutorEvent("tutor.marketplace.edit_availability.cancels_save");
        onClose();
      }}
    >
      <BodyText>Based on your changes, all affected families are:</BodyText>
      <div sx={sxRowsWrapper}>
        {rows.map((row, index) => (
          <Fragment key={index}>
            <div sx={sxDivider} />
            <BodyText sx={sxName}>{row[0].name}</BodyText>
            <div sx={sxTime}>
              {row.map(({ day, times }) => (
                <BodyText key={day} kind="danger">
                  {startCase(day)} {times[0]}
                </BodyText>
              ))}
            </div>
          </Fragment>
        ))}
        <div sx={sxDivider} />
      </div>
      <BodyText>
        The affected families will be asked to pick a new time on your schedule. If they can’t find time, they’ll either
        be assigned to a new tutor, or cancel their subscription.
      </BodyText>
      <div sx={sxButtonRow}>
        <Button
          type="button"
          data-name=""
          kind="secondary"
          size="l"
          sx={sxButton}
          onClick={() => {
            logTutorEvent("tutor.marketplace.edit_availability.cancels_save");
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          data-name=""
          kind="primary"
          size="l"
          sx={sxButton}
          onClick={() => {
            logTutorEvent("tutor.marketplace.edit_availability.confirms_save");
            onSave();
          }}
        >
          Save changes
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default ConflictConfirmationModal;

const sxRowsWrapper: ThemeUIStyleObject = {
  marginTop: "dt_m",
  marginBottom: "dt_m",
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "dt_m",
};

const sxDivider: ThemeUIStyleObject = {
  borderTop: "dt_divider",
  gridColumn: "1 / span 2",
};

const sxName: ThemeUIStyleObject = {
  gridColumn: 1,
};

const sxTime: ThemeUIStyleObject = {
  color: "dt_content_danger",
  gridColumn: 2,
  textDecoration: "line-through",
};

const sxButtonRow: ThemeUIStyleObject = {
  display: "flex",
  gap: "dt_m",
};

const sxButton: ThemeUIStyleObject = {
  width: "100%",
  marginTop: "dt_l",
};
