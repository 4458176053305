import { logEvent } from "@classdojo/log-client";
import Cookie from "js-cookie";
import cookies from "./cookies";
import { IS_TEST_SERVER } from "src/constants/client";
import { FeatureName } from "src/hooks/useFeatureSwitches";
import { useOnFirstRender } from "@web-monorepo/hooks";

const getUtmMetadata = () => {
  const cookie = cookies.sourceUtmCampaigns;
  const cookieValue = Cookie.get(cookie.name);
  if (cookieValue) {
    return JSON.parse(cookieValue as string) as any;
  }
};

type TutorEventData =
  | string
  | (Parameters<typeof logEvent>[0] & {
      experiments?: FeatureName[];
    });

export const logTutorEvent = async (rawData: TutorEventData): Promise<unknown> => {
  if (typeof rawData === "string") {
    return logTutorEvent({ eventName: rawData });
  }

  setTimeout(() => {
    const { entityId, experiments, metadata } = rawData;

    const utmMetadata = getUtmMetadata();
    const eventMetadata = {
      ...utmMetadata,
      ...metadata,
      isTest: IS_TEST_SERVER,
    };

    return logEvent({
      eventName: rawData.eventName,
      metadata: eventMetadata,
      entityId,
      experiments,
    });
  }, 0);
};

export const useLogTutorEventOnFirstRender = (event: TutorEventData) => {
  useOnFirstRender(() => {
    logTutorEvent(event);
  });
};
